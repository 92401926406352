<template>
  <div>
    <template v-for="slide in slides">
      <slide-item
        :key="slide.id"
        :slide="slide"
        :active-presentation-id="presentationId"
        :active-presentation-slide="activePresentationSlide"
        :img-resizer-params="`/preview_424_244_75`"
        :cloudflare-options="{ width: 200, quality: 70 }"
      >
        <template v-if="withOrder" v-slot="{ slide }">
          <div class="slide__order">{{ slide.ord }}</div>
        </template>
      </slide-item>
    </template>
  </div>
</template>

<script>
import SlideItem from "./SpeakerPresentationControlSlideItem";

export default {
  name: "SpeakerPresentationSlides",
  components: {
    SlideItem,
  },
  props: {
    withOrder: {
      type: Boolean,
      default: false,
    },
    slides: {
      type: Array,
      required: true,
    },
    presentationId: {
      type: Number,
      default: null,
    },
    activePresentationSlide: {
      type: Number,
      default: null,
    },
  },
};
</script>
