<template>
  <div :class="$style.panel" class="flex align-center">
    <ws-icon v-if="icon" xxl light :class="$style.panel__icon">poll</ws-icon>
    <ws-tooltip theme="ws-default" :max-width="150">
      <template #content>
        <span>{{ $t("Tests.errorStopPoll") }}</span>
      </template>
      <template #target>
        <ws-button v-if="!!activePollId" color="danger" font-size="lg" @click="stopPollHandler">
          <ws-icon lg>times</ws-icon>
          {{ $t("Tests.stopPoll") }}
        </ws-button>
        <ws-button
          v-if="!!activeResultsId"
          :class="$style.panel__btnResults"
          font-size="lg"
          @click="stopResultsHandler"
        >
          <ws-icon lg>eye-slash</ws-icon>
          {{ $t("Tests.hideResults") }}
        </ws-button>
      </template>
    </ws-tooltip>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { room, socket, speakerPolls } from "@/store/modules/store.namespaces";
import { SHOW_QUIZ_RESULT, TOGGLE_QUIZ } from "@/store/modules/speaker/modules/polls/action-types";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";
import { ACTIVE_POLL_ID, ACTIVE_RESULTS_ID } from "@/store/modules/common/getter-types";

import { WsTooltip } from "@whenspeak/ui-kit";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

// TODO при редизайне возможно изменится эта часть и будет более красиво и правильно выглядеть
/**
 * Панель в которой можно остановить результаты или опрос из списка презентаций или презентации
 *
 * @vue-prop {boolean} [icon=true] - отображение иконки
 * @vue-prop {number} [activePollId=0] - id запущенного опроса
 * @vue-prop {number} [activeResultsId=0] - id запущенного результата опроса
 */
export default {
  name: "ContainerTestAndPollPanel",
  components: {
    WsTooltip,
    WsIcon,
    WsButton,
  },
  props: {
    icon: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(room, {
      activePollId: ACTIVE_POLL_ID,
      activeResultsId: ACTIVE_RESULTS_ID,
    }),
  },
  methods: {
    ...mapMutations(socket, {
      setSocketObj: SET_SOCKET_OBJ,
    }),
    ...mapActions(speakerPolls, {
      toggleQuiz: TOGGLE_QUIZ,
      stopResults: SHOW_QUIZ_RESULT,
    }),
    /**
     * Остановить показ опроса
     */
    stopPollHandler() {
      const data = {
        room: this.$route.params.id,
        voting: this.activePollId,
      };
      const tick = {
        type: "closeQuiz",
        obj: { id: 0 },
        id: 0,
      };
      this.setSocketObj(tick);
      this.toggleQuiz(data);
    },
    /**
     * Остановить показ результатов
     */
    stopResultsHandler() {
      const data = {
        obj: { event: "hide" },
        type: "showQuizResult",
      };
      this.setSocketObj(data);
      this.stopResults(data);
    },
  },
};
</script>

<style lang="scss" module>
.panel {
  margin-top: 13px;

  &__btnResults {
    color: $white;

    @include scssToCssVariable(
      (
        button-bg-color: $blue,
        button-bg-active-color: $blue-darken,
      )
    );
  }

  &__icon {
    margin-right: 10px;
  }

  &__message {
    color: #ff2416;
  }
}
</style>
