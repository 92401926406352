<template>
  <smart-modal :visible="visible" @close="close">
    <template #full>
      <div :class="$style.room">{{ room }}</div>
      <div :class="$style.message">{{ $t("SpeakerPresentation.pin_message") }}</div>
      <ws-button color="primary" lg @click="close">{{ $t("common.close") }}</ws-button>
    </template>
  </smart-modal>
</template>

<script>
import SmartModal from "@/components/common/modals/SmartModal";
import WsButton from "@/components/base/WsButton";

/**
 * Уведомления для спикера о том что пин код будет показан участникам
 *
 * @vue-prop {boolean} visible - показан попап или нет
 * @vue-computed {string} room - номер комнаты
 * @vue-event close - скрыть пин
 */
export default {
  name: "SpeakerPresentationPinNotify",
  components: {
    SmartModal,
    WsButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    room() {
      return this.$route.params.id;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" module>
.room {
  margin-bottom: 5px;
  font-size: 1.8em;
  text-align: center;
}

.message {
  margin-bottom: 10px;
  text-align: center;
}
</style>
