<script>
import { mapGetters } from "vuex";
import {
  CURRENT_PRESENTATION,
  CURRENT_PRESENTATION_SLIDE,
  CURRENT_PRESENTATION_SLIDES,
  SELECTED_SLIDE_ORD,
} from "@/store/modules/presentation/getter-types";
import { presentation } from "@/store/modules/store.namespaces";

export default {
  name: "withPresentationInfoMixin",
  computed: {
    ...mapGetters(presentation, {
      activePresentationSlide: SELECTED_SLIDE_ORD,
      currentActiveSlide: CURRENT_PRESENTATION_SLIDE,
      presentation: CURRENT_PRESENTATION,
      presentationSlides: CURRENT_PRESENTATION_SLIDES,
    }),
    presentationId() {
      return this.presentation?.id;
    },
  },
};
</script>
