<template>
  <div
    class="presentation-slides-line"
    :class="{ 'presentation-slides-line--mobile': isSmallSlides }"
  >
    <presentation-slides-container
      ref="slidesContainer"
      class="presentation-slides-line__slides"
      :with-order="!isSmallSlides"
    />
  </div>
</template>

<script>
import PresentationSlidesContainer from "../containers/SpeakerPresentationSlidesContainer";

export default {
  name: "SpeakerPresentationSlidesLine",
  components: {
    PresentationSlidesContainer,
  },
  props: {
    isSmallSlides: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/_vars";

.presentation-slides-line {
  position: relative;
  display: flex;
  min-height: calc(16px + 63px);
  margin-bottom: 5px;
  overflow: hidden;

  &--mobile {
    padding: 0 20px;
  }

  &__slides {
    display: flex;
    padding: 8px;
    overflow-y: hidden;
    overflow-x: auto;
    cursor: pointer;

    &::after {
      display: block;
      width: 8px;
      min-width: 8px;
      height: 57px;
      content: "";
    }

    ::v-deep .slide {
      position: relative;
      box-sizing: content-box;
      flex-basis: 84px;
      flex-shrink: 0;
      width: 84px;
      height: 63px;
      margin-right: 7px;
      overflow: hidden;
      cursor: pointer;
      background: no-repeat center center;
      background-size: cover;
      border: solid 3px @slide-thumb-border-color;
      border-radius: 4px;
      transition: all ease 0.3s;

      &--active {
        border-color: @slide-thumb-border-color-active;
      }

      &:not(:last-of-type) {
        margin-right: 6px;
      }

      &__img {
        position: relative;
        top: 50%;
        left: 50%;
        z-index: 0;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
      }
    }
  }

  @media only screen and (min-width: 1025px) {
    min-height: initial;

    &::after {
      display: none;
    }

    &__slides {
      width: calc(100% - 32px);
      padding: 2px 3px 10px;
      margin: auto auto 2px;

      ::v-deep .slide {
        flex-basis: 174px;
        width: 174px;
        height: 128px;

        &__icon {
          font-size: 36px;
        }

        & .slide__order {
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 1;
          width: 41px;
          height: 23px;
          font-size: 18px;
          line-height: 23px;
          text-align: center;
          color: @slide-thumb-text-color;
          background-color: @slide-thumb-border-color;
          border-radius: 4px 0 0 0;
          transition: background-color 0.3s, color 0.3s;
        }

        &--active .slide__order {
          color: @slide-thumb-text-color-active;
          background-color: @slide-thumb-border-color-active;
        }
      }
    }
  }
}
</style>
