<template>
  <div class="presentation-control-tile">
    <presentation-slides
      ref="slides"
      class="tile-pres"
      :with-order="true"
      :slides="presentationSlides"
      :presentation-id="presentationId"
      :active-presentation-slide="activePresentationSlide"
    />
  </div>
</template>

<script>
import PresentationSlides from "./SpeakerPresentationSlides";
import withPresentationInfoMixin from "./mixins/withPresentationInfo.mixin";

export default {
  name: "PresentationControlTile",
  components: {
    PresentationSlides,
  },
  mixins: [withPresentationInfoMixin],
};
</script>

<style scoped lang="less">
.presentation-control-tile {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.tile-pres {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  padding: 7px;
  overflow-y: auto;

  ::v-deep .slide {
    position: relative;
    width: ~"calc(33.3% - 16px)";
    padding-top: ~"calc(25% - 10px)";
    margin: 8px;
    overflow: hidden;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 0 0 1px #ddd;

    .container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 4px;

      &-image--cover {
        object-fit: cover;
      }
    }

    &__img {
      width: 100%;
    }

    &__order {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 60px;
      height: 33px;
      font-size: 24px;
      line-height: 33px;
      color: #000;
      text-align: center;
      background-color: #ddd;
      border-radius: 4px 0 0 0;
    }

    &--active {
      box-shadow: 0 0 0 3px #f28b00;

      .slide__order {
        color: #fff;
        background-color: #f28b00;
      }
    }

    &--icon {
      align-items: center;
      justify-content: center;
      font-size: 60px;
    }
  }
}
</style>
