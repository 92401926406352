<template>
  <div class="mode-type">
    <template v-for="(mode, idx) in modeTypes">
      <div
        :key="idx"
        class="icon"
        :class="[mode.icon, { 'icon--active': value === mode.type }]"
        @click="$emit('input', mode.type)"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: "PresentationViewSwitcher",
  props: {
    value: {
      type: String,
      default: "line",
    },
  },
  data() {
    return {
      modeTypes: [
        {
          icon: "icon-grid",
          type: "tile",
        },
        {
          icon: "icon-list",
          type: "line",
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
.mode-type {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  min-height: 40px;
  padding: 0 27px;
  font-size: 16px;

  .icon {
    margin-left: 15px;
    color: #898989;
    cursor: pointer;

    &--active {
      color: #f28b00;
    }
  }
}
</style>
