<template>
  <div
    class="presentation-control-buttons"
    :class="{
      'presentation-control-buttons--tile': currentViewType === 'tile',
      'presentation-control-buttons--has-slot': !isMobile,
    }"
  >
    <button
      class="presentation-control-buttons__button"
      :disabled="changeSlideIsBusy"
      @click="runNextSlide(-1)"
    >
      <ws-icon light color="primary">chevron-left</ws-icon>
    </button>

    <div v-if="!isMobile" class="presentation-control-buttons__content">
      <slot></slot>
    </div>

    <button
      class="presentation-control-buttons__button presentation-control-buttons__button-forward"
      :disabled="changeSlideIsBusy"
      @click="runNextSlide(1)"
    >
      <ws-icon light color="primary">chevron-right</ws-icon>
    </button>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { presentation, socket } from "@/store/modules/store.namespaces";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";
import { RUN_NEXT_SLIDE } from "@/store/modules/presentation/action-types";
import WsIcon from "@/components/base/WsIcon";

const SLIDE_MARGIN = 6;
const SLIDE_TILE_MARGIN = 16;
const SLIDES_IN_LINE = 3;
const SCROLL_DURATION = 150;

export default {
  name: "SpeakerPresentationControlButtons",
  components: {
    WsIcon,
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    slidesRef: {
      type: [Object, HTMLDivElement],
    },
    currentViewType: {
      type: String,
      default: "line",
    },
    presentationInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      refsArray: [],
      scrollToLineSwipe: 0,
      watchSlidesFilledTimer: 0,
    };
  },

  computed: {
    ...mapState(presentation, ["changeSlideIsBusy"]),
    isTileViewType() {
      return this.currentViewType === "tile";
    },
    currentSlideIndex() {
      return this.presentationInfo.slideIndex;
    },
    currentPresentationId() {
      return this.presentationInfo.id;
    },
  },

  watch: {
    slidesRef: {
      handler(slides) {
        if (slides instanceof HTMLDivElement) {
          this.startWatchSlidesFilled(slides);
        }
      },
      immediate: true,
    },
    currentSlideIndex() {
      this.scrollToCurrentSlide();
    },
    isTileViewType() {
      this.scrollToCurrentSlide(0);
    },
    currentPresentationId() {
      this.scrollToCurrentSlide(0);
    },
  },

  activated() {
    this.scrollToCurrentSlide(0);
  },
  mounted() {
    this.scrollToCurrentSlide(0);
  },

  methods: {
    ...mapMutations(socket, {
      setSocketObj: SET_SOCKET_OBJ,
    }),
    ...mapActions(presentation, {
      runNextSlide: RUN_NEXT_SLIDE,
    }),
    async scrollToCurrentSlide(duration) {
      await this.$nextTick();
      this.refsArray = Array.from(this.slidesRef.querySelectorAll(".slide"));
      this.scrollToPosition(this.refsArray, this.currentSlideIndex - 1, duration);
    },
    getScrollPositon(slidesRef, slideIndex) {
      return slidesRef
        .slice(0, (this.isTileViewType && slideIndex + 1) || slideIndex)
        .map(ref => {
          const rect = ref.getBoundingClientRect();
          return this.isTileViewType ? rect.height + SLIDE_TILE_MARGIN : rect.width + SLIDE_MARGIN;
        })
        .reduce((accumulator, value, idx) => {
          if (this.isTileViewType) {
            // Расчитывается высота для скрола в плиточном режиме
            return idx % SLIDES_IN_LINE === 0 && idx > 0 ? accumulator + value : accumulator;
          }
          return accumulator + value;
        }, 0);
    },
    scrollToPosition(slidesRef, slideIndex, duration = SCROLL_DURATION) {
      this.scrollToLineSwipe = this.getScrollPositon(slidesRef, slideIndex);
      const options = { duration };
      options[this.isTileViewType ? "top" : "left"] = this.scrollToLineSwipe;
      this.$scrollTo(this.slidesRef, options);
    },
    startWatchSlidesFilled(ref) {
      this.watchSlidesFilledTimer = setInterval(() => {
        const refsArray = Array.from(ref.querySelectorAll(".slide"));
        if (refsArray.length) {
          clearInterval(this.watchSlidesFilledTimer);
          this.watchSlidesFilledTimer = 0;
          this.refsArray = refsArray;
        }
      }, 500);
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/styles/_mixin";

@baseWidth: 134px;

.presentation-control-buttons {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  padding: 0 20px 12px;

  &--has-slot {
    grid-template-columns: max-content 1fr max-content;
  }

  &__button {
    .button-reset();

    display: flex;
    justify-content: center;
    align-items: center;
    width: @baseWidth;
    height: 186px;
    font-size: 48px;
    line-height: 1;
    border: 1px solid var(--base-color);
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &__button-forward {
    width: auto;
    min-width: calc(320px - @baseWidth);
    font-size: 72px;
    border-left-width: 0;
    border-radius: 0 12px 12px 0;
  }

  @media only screen and (min-width: 1024px) {
    align-items: center;
    flex-grow: 1;
    max-width: 1070px;
    margin: 0 auto;
    overflow: hidden;

    &__content {
      position: relative;
      height: 100%;
      overflow: auto;
    }

    &__button,
    &__button-forward {
      width: 40px;
      min-width: 40px;
      font-size: 36px;
      border: initial;
    }

    &--tile {
      height: calc(100vh - 60px - 40px);
    }
  }
}
</style>
