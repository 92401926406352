<template>
  <div
    v-if="presentation"
    class="presentation-control-panel"
    :class="{
      'presentation-control-panel--mobile': compactView,
    }"
  >
    <div class="info-block">
      <div class="title">
        <span>{{ presentation.name }}</span>
      </div>

      <div v-show="watchDigits" class="time info-block--item">
        <i class="icon-clock"></i>
        <div>{{ watchDigits }}</div>
      </div>

      <div class="info-block--item">
        <i class="icon-layers"></i>
        <div class="slide-num">
          <span>{{ currentSlideIndex }}</span>
          {{ $t("SpeakerPresentation.from") }}
          <span>{{ presentation.slidesArray.length }}</span>
        </div>
      </div>

      <div class="pin info-block--item" @click="showPin">
        <i class="icon-key"></i>
        <div>{{ $t("SpeakerPresentation.pin_show_label") }}</div>
      </div>

      <container-test-and-polls-panel v-if="hasActivePolls" class="info-block--item" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { presentation, room } from "@/store/modules/store.namespaces";

import ContainerTestAndPollsPanel from "@/containers/speaker/ContainerTestAndPollsPanel";

export default {
  name: "PresentationControlPanel",
  components: {
    ContainerTestAndPollsPanel,
  },
  props: {
    currentViewType: {
      type: String,
      default: "line",
    },
  },

  data() {
    return {
      isBusy: false,
      pinState: false,
      stopwatchInterval: null,
      scrollToLineSwipe: 0,
      watchDigits: "0:00:00",
      viewType: "tile",
      refsArray: [],
    };
  },

  computed: {
    ...mapState(room, {
      hasActivePolls: state => !!state.roomInfo.Quiz || !!state.activeResultId,
    }),
    ...mapState(presentation, ["presentations", "currentPresentationInfo"]),
    presentation() {
      return this.presentations.find(item => item.id === this.currentPresentationInfo.id);
    },
    compactView() {
      return this.$mqToLaptop;
    },
    currentSlideIndex() {
      return this.currentPresentationInfo.slideIndex;
    },
  },

  created() {
    this.startStopwatch();
  },
  beforeDestroy() {
    if (this.stopwatchInterval) {
      clearInterval(this.stopwatchInterval);
    }
  },

  methods: {
    showPin() {
      this.$emit("show-pin");
    },
    startStopwatch() {
      if (this.stopwatchInterval) {
        clearInterval(this.stopwatchInterval);
      }
      const startTimestamp = new Date().getTime();
      const runningTime = 0;

      this.stopwatchInterval = setInterval(() => {
        const stopwatchTime = new Date().getTime() - startTimestamp + runningTime;
        this.watchDigits = this.returnFormattedToMilliseconds(stopwatchTime);
      }, 1000);
    },
    returnFormattedToMilliseconds(time) {
      let seconds = Math.floor((time / 1000) % 60);
      let minutes = Math.floor((time / (1000 * 60)) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);

      seconds = seconds < 10 ? `0${seconds}` : seconds;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${hours}:${minutes}:${seconds}`;
    },
  },
};
</script>

<style scoped lang="less">
.presentation-control-panel {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 377px;
  height: 100%;
  padding: 23px 40px 0 46px;
  border-left: solid 1px #cacaca;
  overflow-x: hidden;
  overflow-y: auto;

  &--mobile {
    position: static;
    flex-grow: 1;
    width: 100%;
    height: auto;
    padding: 17px 20px 10px;
    margin-bottom: 15px;
    border-left: none;
  }

  .info-block {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    word-wrap: break-word;

    &--item {
      margin-top: 13px;

      div {
        width: 100%;
      }
    }

    * {
      flex-shrink: 0;
    }

    > div {
      display: flex;
    }

    i {
      margin-right: 14px;
      font-size: 24px;
    }

    .title {
      display: block;
      margin-bottom: 32px;
      font-size: 21px;
      line-height: 22px;
    }

    .time {
      font-size: 24px;
    }

    .pin {
      cursor: pointer;
    }
  }
}
</style>
