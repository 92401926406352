<template>
  <keep-alive>
    <div v-if="!currentPresentation" class="presentation-control-empty">
      <span>{{ $t("Presentation.empty") }}</span>
    </div>
    <div
      v-else
      class="presentation-control"
      :class="{
        'presentation-control--has-control-panel': !compactView,
        'presentation-control--mobile': compactView,
      }"
    >
      <div v-if="!compactView" class="presentation-control__desktop-view">
        <presentation-view-switcher v-model="viewType" />
        <presentation-control-buttons
          class="presentation-control__buttons"
          :class="{
            'presentation-control__buttons--tile': viewType === 'tile',
          }"
          :current-view-type="viewType"
          :slides-ref="slidesRef"
          :presentation-info="currentPresentationInfo"
        >
          <presentation-control-tile v-if="viewType === 'tile'" ref="tileControl" />
          <presentation-control-line v-if="viewType === 'line'" />
        </presentation-control-buttons>
      </div>

      <presentation-control-panel
        :compact-view="compactView"
        class="presentation-control__panel"
        @show-pin="togglePin(true)"
      />

      <presentation-slides-line
        v-if="displaySlidesLine"
        ref="slidesLine"
        class="presentation-control__slides-line"
        :is-small-slides="compactView"
      />

      <presentation-control-buttons
        v-if="compactView"
        class="presentation-control__mobile-buttons"
        current-view-type="line"
        :slides-ref="slidesRef"
        :presentation-info="currentPresentationInfo"
        is-mobile
      />
      <pin-notify :visible="pinVisible" @close="togglePin(false)" />
    </div>
  </keep-alive>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import { presentation, room, socket, speaker } from "@/store/modules/store.namespaces";
import { SET_SOCKET_OBJ } from "@/store/modules/socket/mutation-types";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import {
  CURRENT_PRESENTATION,
  CURRENT_PRESENTATION_SLIDES,
} from "@/store/modules/presentation/getter-types";
import { CHANGE_SLIDE_BY_KEY } from "@/store/modules/presentation/action-types";
import redirectNotMainSpeakerMixin from "@/components/speaker/mixins/redirectNotMainSpeakerMixin";
import PinNotify from "@/components/speaker/presentation/SpeakerPresentationPinNotify";
import PresentationControlTile from "./PresentationControlTile";
import PresentationControlLine from "./PresentationControlLine";
import PresentationViewSwitcher from "./PresentationViewSwitcher";
import PresentationControlPanel from "./PresentationControlPanel";
import PresentationControlButtons from "./SpeakerPresentationControlButtons";
import PresentationSlidesLine from "./components/SpeakerPresentationSlidesLine";

export default {
  name: "PresentationControl",
  components: {
    PresentationControlTile,
    PresentationControlLine,
    PresentationViewSwitcher,
    PresentationControlPanel,
    PresentationControlButtons,
    PresentationSlidesLine,
    PinNotify,
  },
  mixins: [redirectNotMainSpeakerMixin],
  data() {
    return {
      pinVisible: false,
      stopwatchInterval: null,
      watchDigits: null,
      viewType: "line",
      slidesRef: {},
    };
  },

  computed: {
    ...mapState(room, {
      room: "roomNumber",
      roomInfo: "roomInfo",
    }),
    ...mapState(speaker, ["burgerMenuOpened"]),
    ...mapState(presentation, ["currentPresentationInfo"]),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    ...mapGetters(presentation, {
      currentPresentationSlides: CURRENT_PRESENTATION_SLIDES,
      currentPresentation: CURRENT_PRESENTATION,
    }),
    compactView() {
      return this.$mqToLaptop;
    },
    displaySlidesLine() {
      return this.viewType === "line" || this.compactView;
    },
    currentSlideIndex() {
      return this.currentPresentationInfo.slideIndex;
    },
  },

  watch: {
    compactView() {
      this.$nextTick(this.setSlidesRef);
    },
    viewType() {
      this.$nextTick(this.setSlidesRef);
    },
    "currentPresentationInfo.id": function(val) {
      if (val !== +this.$route.params.presId) {
        this.$router.replace({
          name: this.$route.name,
          params: { ...this.$route.params, presId: val },
        });
      }
    },
  },

  mounted() {
    this.setSlidesRef();
    document.addEventListener("keydown", this.changeSlideByKey);
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this.changeSlideByKey);
  },

  methods: {
    ...mapMutations(socket, {
      setSocketObj: SET_SOCKET_OBJ,
    }),
    ...mapActions(presentation, {
      changeSlideByKey: CHANGE_SLIDE_BY_KEY,
    }),
    togglePin(state = !this.pinVisible) {
      const tick = { state, type: "pin" };
      this.setSocketObj(tick);
      this.pinVisible = state;
    },
    setSlidesRef() {
      if (!this.currentPresentation) return;
      if (this.viewType === "tile" && !this.compactView) {
        this.slidesRef = this.$refs.tileControl.$refs.slides.$el;
        return;
      }
      this.slidesRef = this.$refs.slidesLine.$refs.slidesContainer.$refs.slides.$el;
    },
  },
};
</script>

<style scoped lang="less">
.presentation-control {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  overflow: auto;

  &--has-control-panel {
    padding-right: 377px;
  }

  &__desktop-view {
    display: grid;
    flex-grow: 1;
    grid-template-rows: 40px 1fr;
    width: 100%;
    height: 80%;
  }

  &__slides-line {
    flex: 0 0 auto;
  }

  &__buttons--tile {
    max-width: 100%;
    margin: 0;
  }
}

.presentation-control-empty {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
}
</style>
