<template>
  <div class="slide" :class="slideClasses" @click="changeSlide">
    <component
      :is="slideComponent"
      v-if="slideComponent"
      :slide="slide"
      :is-active="isActive"
      :imgResizerParams="imgResizerParams"
      :cloudflare-options="cloudflareOptions"
    />
    <slot v-bind:slide="slide" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { presentation } from "@/store/modules/store.namespaces";
import { CHANGE_SLIDE } from "@/store/modules/presentation/action-types";
import slideItemMixin from "./mixins/slideItemMixin";

const DEFAULT_SLIDE_IMG_SIZE = "/resize_1920_1080_75";

/**
 * Slides navigation panel
 *
 * @vue-prop {number} [activePresentationSlide=null] - Текущий выбраный слайд для презентации
 * @vue-prop {number} [activePresentationId=null] - ID текущей презентации
 * @vue-prop {boolean} [isActive=false] - Точно не активный ли слайд
 */
export default {
  name: "SpeakerPresentationControlSlideItem",
  mixins: [slideItemMixin],
  props: {
    activePresentationSlide: {
      type: Number,
      default: null,
    },
    activePresentationId: {
      type: Number,
      default: null,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    imgResizerParams: {
      type: String,
      default: DEFAULT_SLIDE_IMG_SIZE,
    },
    // Опции для ресайзера изображений через сервис cloudflare
    cloudflareOptions: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    slideClasses() {
      return {
        "slide--active": this.activePresentationSlide === this.slide?.ord,
        "slide--icon": this.isIcon,
      };
    },
    slideComponent() {
      return this.componentSlidesMap[`${this.slide?.type}-slide`] || null;
    },
  },
  methods: {
    ...mapActions(presentation, {
      changePresentationSlide: CHANGE_SLIDE,
    }),
    changeSlide() {
      if (this.isActive) return;
      this.changePresentationSlide(this.slide);
    },
  },
};
</script>

<style scoped lang="less">
.slide {
  &__icon::before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    text-shadow: 0 0 5px #fff;
    transform: translate(-50%, -50%);
  }
}
</style>
