<script>
import { SLIDE_TYPE } from "@/constants/presentation/slide-const";
import { IMAGE_TYPE } from "@/constants/presentation/presentation-const";
/* eslint-disable max-len */
import QuizSlide from "@/components/event/room/Presentation/components/presentation-slider/presentation-slides/QuizSlide";
import VideoSlide from "@/components/event/room/Presentation/components/presentation-slider/presentation-slides/VideoSlide";
import ImageSlide from "@/components/event/room/Presentation/components/presentation-slider/presentation-slides/ImageSlide";
import TestSlideResult from "@/components/common/polls/components/common/TestSlideResult.vue";
import TimerSlide from "@/components/common/presentation/components/TimerSlide";
import { makeUrl } from "@/utils";

export default {
  name: "SlideItemMixin",
  props: {
    slide: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isTestSlideResult() {
      return this.slide?.type === "results";
    },
    isVideo() {
      return this.slide?.type === "video";
    },
    isTest() {
      return this.slide?.type === "test";
    },
    isExtimg() {
      return this.slide?.type === IMAGE_TYPE.extimg;
    },
    isTimerSlide() {
      return this.slide?.type === "timer";
    },
    isIcon() {
      return this.isVideo || this.isTest || this.isTestSlideResult || this.isTimerSlide;
    },
    slideImgPath() {
      if (this.isTest || this.isTestSlideResult || this.isTimerSlide) return null;
      return this.isVideo ? makeUrl(this.slide.video_slide_preview) : makeUrl(this.slide.path);
    },
    imgUrl() {
      if (this.isTest) return null;
      return this.buildImageUrl();
    },

    /**
     * Because your can't use 'video' as bind component name
     *
     * @see [Vue warn]: Do not use built-in or reserved HTML elements as component id
     */
    componentSlidesMap() {
      return {
        "test-slide": QuizSlide,
        "video-slide": VideoSlide,
        "extimg-slide": ImageSlide,
        "img-slide": ImageSlide,
        "results-slide": TestSlideResult,
        "timer-slide": TimerSlide,
      };
    },
  },
  methods: {
    buildImageUrl() {
      if (this.slide.type === SLIDE_TYPE.extimg) {
        return this.slide.path;
      }
      return makeUrl(this.slideImgPath);
    },
  },
};
</script>
