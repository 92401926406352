<template>
  <div class="presentation-control-line">
    <slide-item
      :key="(currentActiveSlide && currentActiveSlide.id) || 0"
      class="presentation-control-line__slide"
      :slide="currentActiveSlide || {}"
      :active-presentation-id="presentationId"
      :active-presentation-slide="activePresentationSlide"
      :cloudflare-options="{ quality: 70 }"
      is-active
    />
  </div>
</template>

<script>
import SlideItem from "./SpeakerPresentationControlSlideItem";
import withPresentationInfoMixin from "./mixins/withPresentationInfo.mixin";

export default {
  name: "PresentationControlLine",
  components: {
    SlideItem,
  },
  mixins: [withPresentationInfoMixin],
};
</script>

<style scoped lang="less">
.presentation-control-line {
  position: relative;
  width: 100%;
  height: 100%;

  &__slide {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform: translate(-50%, -50%);

    ::v-deep .slide {
      &__icon {
        font-size: 60px;
      }
    }
    ::v-deep .container--image {
      border-radius: 4px;
      object-fit: contain;
      overflow: hidden;
    }
  }
}
</style>
