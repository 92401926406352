<template>
  <presentation-slides
    ref="slides"
    :with-order="withOrder"
    :slides="presentationSlides"
    :presentation-id="presentationId"
    :active-presentation-slide="activePresentationSlide"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { presentation } from "@/store/modules/store.namespaces";
import {
  CURRENT_PRESENTATION,
  CURRENT_PRESENTATION_SLIDES,
  SELECTED_SLIDE_ORD,
} from "@/store/modules/presentation/getter-types";
import PresentationSlides from "../SpeakerPresentationSlides";

export default {
  name: "SpeakerPresentationSlidesContainer",
  components: {
    PresentationSlides,
  },
  props: {
    withOrder: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(presentation, {
      activePresentationSlide: SELECTED_SLIDE_ORD,
      presentation: CURRENT_PRESENTATION,
      presentationSlides: CURRENT_PRESENTATION_SLIDES,
    }),
    presentationId() {
      return this.presentation?.id;
    },
  },
};
</script>
